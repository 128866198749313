<template>
    <component :is="filterItem.filterEditor" :filterObject="filterObject" :filterItem="filterItem"  :dropdown="dropdown" :hideOperators="hideOperators"></component>
</template>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        columnName: String,
        gridRef: null,
        dataObject: null,
        operator: null,
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null
    });
</script>

<script>
    import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';

    const String = useAsyncComponent('o365.vue.components.FilterEditor.String.vue');
    const Number = useAsyncComponent('o365.vue.components.FilterEditor.Number.vue');
    const Date = useAsyncComponent('o365.vue.components.FilterEditor.Date.vue');
    const Distinct = useAsyncComponent('o365.vue.components.FilterEditor.Distinct.vue');
    const Bit = useAsyncComponent('o365.vue.components.FilterEditor.Bit.vue');
    export default {
        components: { String, Number, Date, Distinct, Bit }
    }
</script>
